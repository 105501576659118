import React from 'react';
import Cookies from 'js-cookie';
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import { HydraAdmin, ResourceGuesser, hydraDataProvider as baseHydraDataProvider, fetchHydra as baseFetchHydra } from "@api-platform/admin";
import authProviderFn from './authProvider';
import { Redirect, Route } from "react-router-dom";
import { UserEdit, UserList, UserShow, UserCreate } from './resources/Users';

const entrypoint
  = process.env.REACT_APP_API_ENTRYPOINT;
const cookie_domain = process.env.REACT_APP_COOKIE_DOMAIN

const authProvider = authProviderFn(cookie_domain, entrypoint)

const fetchHeaders = {'Authorization': `Bearer ${Cookies.get('TKN')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
  ...options,
  headers: new Headers(fetchHeaders),
});
const apiDocumentationParser = (entrypoint) => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
  .then(
    ({ api }) => {
      api.resources = api.resources.filter(({ name }) => {
        return ['users'].indexOf(name) !== -1
      });
      return { api };
    },
    (result) => {
      switch (result.status) {
        case 401:
          return Promise.resolve({
            api: result.api,
            customRoutes: [
              <Route path="/" render={() => {
                return Cookies.get('TKN') ? window.location.reload() : <Redirect to="/login" />
              }} />
            ],
          });
        default:
          return Promise.reject(result);
      }
    },
  );

const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

export default props => (
  <HydraAdmin
    dataProvider={ dataProvider }
    authProvider={ authProvider }
    entrypoint={ entrypoint }
  >
    <ResourceGuesser name="users" list={UserList} edit={UserEdit} show={UserShow} create={UserCreate} />
  </HydraAdmin>
);
